import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useAppSelector } from 'store/hook';
import { RootState } from 'store';

export function AuthenticateRoutes() {
  const userAuth = useAppSelector((state: RootState) => state.userAuth);
  return userAuth?.authToken ? <Outlet /> : <Navigate to='/' />;
}

export function UnAuthenticateRoutes() {
  const userAuth = useAppSelector((state: RootState) => state.userAuth);
  return !userAuth?.authToken ? <Outlet /> : <Navigate to='/dashboard' />;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default { AuthenticateRoutes, UnAuthenticateRoutes };
