import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import SideBar from './sidebar';
import Header from './header';
import Footer from './footer';
import { useState } from 'react';
import BreadCrumbs from 'pages/components/breadcrumbs';
import { colors } from 'styles/colors';

const Layout = () => {
  const [sideMenu, setSideMenu] = useState<boolean>(false);
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          background: colors.background.secondaryBackground,
          height: '100%',
          width: '100%'
        }}
      >
        <Box
          sx={{
            background: colors.background.white,
            // borderRight: `2px solid ${colors.text.titanWhite}`,
            width: sideMenu ? 80 : 220,
            height: '100vh',
            overflow: 'hidden',
            position: 'sticky',
            top: 0
          }}
        >
          <SideBar sideMenu={sideMenu} />
        </Box>
        <Box
          flex={1}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            borderLeft: `2px solid ${colors.text.titanWhite}`
          }}
        >
          <Box
            sx={{
              background: colors.background.white,
              borderBottom: `1px solid ${colors.text.titanWhite}`,
              height: 70,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              px: 3,
              position: 'sticky',
              top: 0,
              zIndex: 999
            }}
          >
            <Header onMenuClick={() => setSideMenu(!sideMenu)} />
          </Box>
          <Box
            id='breadCrumbs'
            data-testid='breadCrumbs'
            sx={{
              borderBottom: '1px solid rgba(221, 221, 221, 0.87)',
              background: colors.background.white,
              height: 50,
              px: 4.5,
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <BreadCrumbs />
          </Box>
          <Box
            sx={{
              // height: 'calc(100vh - 200px)',
              padding: '16px 32px'
            }}
          >
            <Outlet />
          </Box>
          <Footer />
        </Box>
      </Box>
    </>
  );
};

export default Layout;
