import React from 'react';
import { Box, Typography } from '@mui/material';
import { FooterCIcon } from 'assets';
import { colors } from 'styles/colors';
const Footer = () => {
  const sxStyle = {
    color: colors.text.darkGray,
    fontSize: 12,
    fontWeight: 400
  };
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: 60,
        px: 3,
        background: colors.background.white,
        borderTop: `1px solid ${colors.text.grey}`,
        marginTop: 'auto'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: 1,
          alignItems: 'center'
        }}
      >
        <Typography sx={sxStyle}>2024</Typography>
        <FooterCIcon />
        <Typography sx={sxStyle}>4UM</Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: 5
        }}
      >
        <Typography sx={sxStyle}>About</Typography>
        <Typography sx={sxStyle}>Support</Typography>
        <Typography sx={sxStyle}>Contact us</Typography>
      </Box>
    </Box>
  );
};

export default Footer;
