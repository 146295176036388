import { Typography, TypographyProps } from '@mui/material';
import { TFunction, TOptions } from 'i18next';
import React from 'react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import { colors } from 'styles/colors';

export type TypographyWithI18NProps = TypographyProps & {
  i18nKey: Parameters<TFunction>[0];
  children?: undefined;
  textPrefix?: React.ReactNode;
  textSuffix?: React.ReactNode;
  options?: TOptions;
  component?: React.ElementType<any>;
  useTrans?: boolean;
  id?: string;
};

const TypographyWithI18N: React.FC<
  TypographyWithI18NProps & WithTranslation
> = ({
  t,
  tReady,
  i18n,
  i18nKey,
  options,
  id = 'typographyWith-i18n',
  textPrefix,
  textSuffix,
  component,
  useTrans,
  ...props
}) => {
  let translation;

  const asObject = typeof i18nKey === 'string' && i18nKey.includes('.');
  if (asObject) {
    const [key, property] = i18nKey.split('.');
    translation = (t(key, { returnObjects: asObject, ...options }) as any)?.[
      property
    ];
  }
  if (translation == null) translation = t(i18nKey, options);

  return (
    <Typography
      id={id}
      data-testid={id}
      component={component || 'p'}
      aria-label={translation}
      color={colors.text.darkGray}
      {...props}
    >
      {textPrefix}
      {useTrans ? <Trans i18nKey={translation} /> : translation}
      {textSuffix}
    </Typography>
  );
};

export default withTranslation()(TypographyWithI18N);
