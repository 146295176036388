/* istanbul ignore file */
import { createTheme } from '@mui/material/styles';
import { colors } from 'styles/colors';

export const customTheme = createTheme({
  palette: {
    primary: {
      main: colors.text.primaryTheme
    },
    secondary: {
      main: colors.text.approxGainsboro
    }
  }
});
