export enum SideBarListType {
  dashboard = 'Dashboard',
  moderate = 'Moderate',
  cms = 'CMS',
  sendInvite = 'Send Invite'
}

export enum ActionsType {
  add = 0,
  edit = 1,
  delete = 2
}

export enum DurationType {
  minutes = 'minutes',
  seconds = 'seconds',
}

export enum BreadCrumpType {
  dashboard = 'Dashboard',
  moderate = 'Moderate',
  cms = 'Content Management System',
  sendInvite = 'Send Invite',
  accountSettings = 'Account Settings',
}

export enum RoutesType {
  dashboard = 'dashboard',
  moderate = 'moderate',
  cms = 'cms',
  sendInvite = 'sendInvite',
  accountSettings = 'accountSettings',
}
