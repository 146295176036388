import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoggedIn: false,
  userProfile: null,
  authToken: null
};

const authSlice = createSlice({
  name: 'userAuth',
  initialState,
  reducers: {
    setLogIn: (state, action) => {
      state.authToken = action.payload.authToken;
      state.userProfile = action.payload.userProfile;
      state.isLoggedIn = true;
    },
    setLogOut: (state) => {
      state.authToken = initialState.authToken;
      state.userProfile = initialState.userProfile;
      state.isLoggedIn = false;
    }
  }
});

export const { setLogIn, setLogOut } = authSlice.actions;

export default authSlice.reducer;
