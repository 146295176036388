import React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { useLocation, useNavigate } from 'react-router-dom';
import { Link, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { colors } from 'styles/colors';
import { BreadCrumpType, RoutesType } from 'models/enum';

const BreadCrumbs = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const pathnames = location.pathname.split('/').filter((x) => x);

  const getTitleType = (type: string) => {
    switch (type) {
      case RoutesType.moderate:
        return BreadCrumpType.moderate;
      case RoutesType.cms:
        return BreadCrumpType.cms;
      case RoutesType.sendInvite:
        return BreadCrumpType.sendInvite;
      case RoutesType.accountSettings:
        return BreadCrumpType.accountSettings;
      default:
        return BreadCrumpType.dashboard;
    }
  };

  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize='small' />}
      aria-label='breadcrumb'
    >
      <Link
        underline='none'
        className='bodyTextBold'
        color={
          pathnames[0] === RoutesType.dashboard
            ? colors.text.primaryTheme
            : colors.text.darkGray
        }
        onClick={() => navigate(RoutesType.dashboard)}
        sx={{ cursor: 'pointer' }}
      >
        {BreadCrumpType.dashboard}
      </Link>
      {pathnames[0] !== RoutesType.dashboard &&
        pathnames.map((_itm, index) => (
          <Typography
            key={`breadCrumbs-${index}`}
            className='bodyTextBold'
            color={colors.text.primaryTheme}
            sx={{
              textTransform: 'capitalize'
            }}
          >
            {getTitleType(_itm)}
          </Typography>
        ))}
    </Breadcrumbs>
  );
};

export default BreadCrumbs;
