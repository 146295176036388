import React from 'react';
import { Box, Typography } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {
  ActiveSideIcon,
  CMSActiveIcon,
  CMSIcon,
  DashboardActiveIcon,
  DashboardIcon,
  LogoOnlyIcon,
  LogoSmallIcon,
  ModerateActiveIcon,
  ModerateIcon,
  SendInviteActiveIcon,
  SendInviteIcon
} from 'assets';
import { SideBarListType } from 'models/enum';
import { useLocation, useNavigate } from 'react-router-dom';
import { colors } from 'styles/colors';

const listItems = [
  {
    title: 'Dashboard',
    active: true,
    count: 0,
    link: 'dashboard'
  },
  {
    title: 'Moderate',
    active: false,
    count: 0,
    link: 'moderate'
  },
  {
    title: 'CMS',
    active: false,
    count: 0,
    link: 'cms'
  },
  {
    title: 'Send Invite',
    active: false,
    count: 0,
    link: 'sendInvite'
  }
];
const SideBar = ({ sideMenu }: any) => {
  const navigate: any = useNavigate();
  const location = useLocation();

  const iconList = (type: string, active: boolean) => {
    switch (type) {
      case SideBarListType.dashboard:
        return active ? <DashboardActiveIcon /> : <DashboardIcon />;
      case SideBarListType.moderate:
        return active ? <ModerateActiveIcon /> : <ModerateIcon />;
      case SideBarListType.cms:
        return active ? <CMSActiveIcon /> : <CMSIcon />;
      case SideBarListType.sendInvite:
        return active ? <SendInviteActiveIcon /> : <SendInviteIcon />;
      default:
        return false;
    }
  };
  const itemSelected = (link: string) => {
    const pathNames = location.pathname.split('/').filter((x) => x);
    return pathNames[0] === link ? true : false;
  };

  const buttonProps = (link: string) => ({
    selected: itemSelected(link),
    onClick: () => {
      navigate(link);
    }
  });

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Box sx={{ height: 70, display: 'flex', alignItems: 'center', px: 3 }}>
        {sideMenu ? <LogoOnlyIcon /> : <LogoSmallIcon />}
      </Box>
      <List sx={{ paddingTop: 0 }}>
        {listItems.map((_itm, index) => (
          <ListItem
            key={`${index}_list`}
            secondaryAction={
              !sideMenu && _itm.count > 0 ? (
                <Typography
                  className='metadataSmall flexCenter'
                  sx={{
                    color: itemSelected(_itm.link)
                      ? colors.text.primaryWhite
                      : colors.text.darkGray,

                    borderRadius: '3.176px',
                    border: '1px solid',
                    borderColor: itemSelected(_itm.link)
                      ? colors.text.brightBlue
                      : colors.text.darkGray,
                    width: 18,
                    height: 18
                    // background: itemSelected(_itm.link)
                    //   ? colors.text.brightBlue
                    //   : colors.text.primaryWhite
                  }}
                >
                  {_itm.count}
                </Typography>
              ) : null
            }
            {...buttonProps(_itm.link)}
            sx={{
              cursor: 'pointer',
              height: 50,
              border: '1px solid',
              borderColor: itemSelected(_itm.link)
                ? colors.text.lightShadeGray
                : colors.text.primaryWhite,
              justifyContent: 'center',
              '&.Mui-selected': {
                background: colors.text.approxIvory
              }
            }}
          >
            {itemSelected(_itm.link) && (
              <ActiveSideIcon style={{ position: 'absolute', left: 0 }} />
            )}
            <ListItemIcon sx={{ minWidth: sideMenu ? 'auto' : 32 }}>
              {iconList(_itm.title, itemSelected(_itm.link))}
            </ListItemIcon>
            {!sideMenu && (
              <ListItemText
                primary={_itm.title}
                primaryTypographyProps={{
                  color: itemSelected(_itm.link)
                    ? colors.text.primaryTheme
                    : colors.text.darkGray,
                  fontSize: '15px',
                  fontStyle: 'normal',
                  fontWeight: itemSelected(_itm.link) ? '600' : '400',
                  lineHeight: 'normal'
                }}
              />
            )}
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default SideBar;
