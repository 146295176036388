import { Button, ButtonProps, SxProps } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from 'styles/colors';

export type AdminButtonProps = ButtonProps & {
  id?: string;
  variant?: string;
  title: string;
  disableElevation?: boolean;
  sxProps?: SxProps;
};

const AdminButton: React.FC<AdminButtonProps> = ({
  id = 'button-contain',
  variant = 'contained',
  title,
  disableElevation = true,
  sxProps,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Button
      className='bodyText'
      id={id}
      data-testid={id}
      variant={variant}
      disableElevation={disableElevation}
      sx={{
        textTransform: 'capitalize',
        ...sxProps,
        '&.MuiButton-outlined': {
          border: `1px solid ${colors.text.approxVeryLightGrey}`,
          background: colors.text.approxWhiteSmoke,
          color: colors.text.darkGray
        }
      }}
      {...props}
    >
      {t(title)}
    </Button>
  );
};

export default AdminButton;
