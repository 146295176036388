import React from 'react';
import {
  Badge,
  Box,
  InputAdornment,
  ListItemIcon,
  Menu,
  MenuItem,
  TextField
} from '@mui/material';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { setLogIn } from 'store/authSlice';
import {
  BellIcon,
  ChatAddIcon,
  LogOutIcon,
  MenuIcon,
  MoreDotIcon,
  SearchIcon,
  SettingIcon
} from 'assets';
import { useNavigate } from 'react-router-dom';
import { colors } from 'styles/colors';
import { useTranslation } from 'react-i18next';
import AdminButton from 'pages/components/button';
import AdminIconButton from 'pages/components/iconButton';
import { RootState } from 'store';
import TypographyWithI18N from 'pages/components/TypographyWithI18N';
import AvatarImg from 'pages/components/avatarImg';
const Header = ({ onMenuClick }: any) => {
  const { t } = useTranslation();
  const navigate: any = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { userProfile }: any = useAppSelector(
    (state: RootState) => state.userAuth
  );
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const dispatch = useAppDispatch();
  const logOutClick = () => {
    dispatch(
      setLogIn({
        authToken: null
      })
    );
    navigate('');
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <AdminIconButton
          id='menu-button'
          aria-label='collapse'
          onClick={onMenuClick}
          icon={<MenuIcon />}
        />

        <TextField
          sx={{ ml: 2 }}
          placeholder='Search Dashboard '
          InputProps={{
            sx: {
              height: 42,
              borderRadius: '3px 0 0px 3px',
              background: '#F7F7F9',
              '& fieldset': { border: 'none' }
            },
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon />
              </InputAdornment>
            )
          }}
        />
        <AdminButton
          title='search'
          className='bodyText'
          sxProps={{
            height: 40,
            borderRadius: '0 3px 3px 0',
            background: colors.text.primaryTheme
          }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 3
        }}
      >
        <Badge badgeContent={0} color='error'>
          <BellIcon />
        </Badge>
        <ChatAddIcon />
        <Box className='flexCenter' ml={2}>
          <AvatarImg
            src=''
            alt='user profile'
            id='user_profile'
            name={userProfile?.username}
            width={28}
            height={28}
            nameStyle={{
              fontSize: 12
            }}
          />
          <TypographyWithI18N
            i18nKey={userProfile?.username}
            sx={{
              textTransform: 'capitalize',
              pl: 1
            }}
          />
        </Box>

        <AdminIconButton
          id='long-button'
          aria-label='more'
          onClick={handleClick}
          icon={<MoreDotIcon />}
        />

        <Menu
          anchorEl={anchorEl}
          id='account-menu'
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1
              },
              '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0
              }
            }
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem onClick={() => navigate('accountSettings')}>
            <ListItemIcon>
              <SettingIcon fontSize='small' />
            </ListItemIcon>
            {t('accountSettings')}
          </MenuItem>
          <MenuItem onClick={logOutClick}>
            <ListItemIcon>
              <LogOutIcon fontSize='small' />
            </ListItemIcon>
            {t('logOut')}
          </MenuItem>
        </Menu>
      </Box>
    </>
  );
};

export default Header;
