import { Route, Routes } from 'react-router-dom';
import Layout from 'pages/layout';
import { Suspense, lazy } from 'react';
import {
  AuthenticateRoutes,
  UnAuthenticateRoutes
} from 'pages/layout/PrivateRoute';

const Loadable = (Component: any) => (props: JSX.IntrinsicAttributes) => (
  <Suspense>
    <Component {...props} />
  </Suspense>
);

const Login = Loadable(lazy(() => import('pages/authentication/Login')));
const Dashboard = Loadable(lazy(() => import('pages/modules/dashboard')));
const Moderate = Loadable(lazy(() => import('pages/modules/moderate')));
const CMS = Loadable(lazy(() => import('pages/modules/cms')));
const SendInvite = Loadable(lazy(() => import('pages/modules/sendInivite')));
const AccountSettings = Loadable(
  lazy(() => import('pages/modules/accountSettings'))
);

const Routers = () => (
  <>
    <Routes>
      <Route element={<UnAuthenticateRoutes />}>
        <Route path='/' element={<Login />} />
      </Route>
      <Route element={<AuthenticateRoutes />}>
        <Route element={<Layout />}>
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/moderate' element={<Moderate />} />
          <Route path='/cms' element={<CMS />} />
          <Route path='/sendInvite' element={<SendInvite />} />
          <Route path='/accountSettings' element={<AccountSettings />} />
        </Route>
      </Route>
    </Routes>
  </>
);

export default Routers;
